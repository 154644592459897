import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingScreenComponent } from './components/landing-screen/landing-screen.component';
import { AuthGuard } from './modules/auth.guard';
import { CanActivate, Router } from '@angular/router';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { RegistrationComponent } from './modules/register/registration/registration.component';
import { TermsComponent } from './components/terms/terms.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { AboutComponent } from './components/about/about.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { GetInvitedComponent } from './modules/get-invited/get-invited/get-invited.component';

const routes: Routes = [
  // { path: '', pathMatch: 'full', component: LandingScreenComponent },
  { path: '', component: LandingScreenComponent },
  { path: 'delete-account', component: DeleteAccountComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'about', component: AboutComponent },
  { path: 'register', component: RegistrationComponent },
  { path: 'forgotPassword', component: ForgotPasswordComponent },
  {
    path: 'get-invited',
    component: GetInvitedComponent,
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'networks',
    loadChildren: () =>
      import('././modules/networks/networks.module').then(
        (m) => m.NetworksModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'chat',
    loadChildren: () =>
      import('././modules/chat/chat.module').then((m) => m.ChatModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'groups',
    loadChildren: () =>
      import('././modules/groups/groups.module').then((m) => m.GroupsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'events',
    loadChildren: () =>
      import('././modules/events/events.module').then((m) => m.EventsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'refer',
    loadChildren: () =>
      import('././modules/refer/refer.module').then((m) => m.ReferModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'jobs',
    loadChildren: () =>
      import('././modules/jobs/jobs.module').then((m) => m.JobsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('././modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'subscriptions',
    loadChildren: () =>
      import('././modules/subscriptions/subscriptions.module').then(
        (m) => m.SubscriptionsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('././modules/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthGuard],
  },
  { path: '', redirectTo: '', pathMatch: 'full' }, // optional: redirect root to landing
  { path: '**', redirectTo: '' }, // optional: wildcard route
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
